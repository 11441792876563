import Bet365Icon from './Bet365Icon';

export type LogoIcon = keyof typeof iconMap;

type Props = {
  size?: 'small' | 'medium';
  icon: LogoIcon;
};

const iconMap = {
  bet365: Bet365Icon,
};

const Logo = ({ size = 'medium', icon }: Props) => {
  const IconComponent = iconMap[icon];

  const dimensions = {
    small: { width: 42, height: 18 },
    medium: { width: 58, height: 24 },
  };

  const { width, height } = dimensions[size];

  return <IconComponent width={width} height={height} />;
};

export default Logo;
